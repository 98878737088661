import React from "react";
import type { TableColumn } from "@adaptive/design-system";
import type { BudgetLineItems } from "@api/jobs";
import { capitalize } from "@utils/capitalize";

import {
  CostBudgetVisibilityName,
  CostsActualColumn,
  CostsActualFooter,
  CostsActualHeader,
  CostsBudgetColumn,
  CostsBudgetFooter,
  CostsBudgetHeader,
  CostsBudgetRemainingTooltip,
  CostsChangesColumn,
  CostsChangesFooter,
  CostsChangesHeader,
  CostsRemainingColumn,
  CostsRemainingFooter,
  CostsRevisedBudgetColumn,
  CostsRevisedBudgetFooter,
  CostsUnpaidColumn,
  CostsUnpaidFooter,
  EmptyColumn,
  RevenuesChangesColumn,
  RevenuesChangesFooter,
  RevenuesChangesHeader,
  RevenuesDrawnColumn,
  RevenuesDrawnFooter,
  RevenuesDrawnHeader,
  RevenuesDrawnRemainingColumn,
  RevenuesDrawnRemainingFooter,
  RevenuesDrawRemainingTooltip,
  RevenuesMarkupColumn,
  RevenuesOwnersBudgetColumn,
  RevenuesOwnersBudgetFooter,
  RevenuesOwnersBudgetHeader,
  RevenuesOwnersBudgetVisibilityName,
  RevenuesRevisedBudgetColumn,
  RevenuesRevisedBudgetFooter,
  TransactionsCategoryColumn,
  TransactionsCategoryFooter,
  TransactionsLineColumn,
  TransactionsLineFooter,
} from "./lines-components";

export type Line = BudgetLineItems & {
  data?: Line[];
  remaining: number;
  budgetRemaining: number;
};

type LineColumn = TableColumn<Line>;

export const getIsDeletable = (row: BudgetLineItems) => {
  return (
    row.jobCostMethod.url !== row.jobCostMethod.parent &&
    !row.spent &&
    !row.hasInvoices &&
    !row.hasChanges
  );
};

/**
 * Columns definition
 */
type GetLineColumnsProps = {
  viewMode: string;
  hasChanges: boolean;
  hasCategories: boolean;
  hasOwnersAmount: boolean;
  hasExternalRevisedBudget: boolean;
};

export const getLineColumns = ({
  viewMode,
  hasChanges,
  hasCategories,
  hasOwnersAmount,
  hasExternalRevisedBudget,
}: GetLineColumnsProps) => {
  const info: LineColumn = {
    id: "info",
    columns: [],
  };

  const isItemsOrQuickbooks = ["items", "quickbooks"].includes(viewMode);

  const nameColumn: LineColumn = {
    id: `name${capitalize(viewMode)}`,
    name: "Line items",
    visibility: {
      name: "Line items",
      mode: "always-visible-not-orderable",
    },
    width: isItemsOrQuickbooks ? "fill" : 350,
    minWidth: isItemsOrQuickbooks ? 350 : undefined,
    orderable: false,
    render: (row) => <TransactionsLineColumn {...row} />,
    sortable: "asc",
  };

  const categoryColumn: LineColumn = {
    id: `category${capitalize(viewMode)}`,
    name: "Categories",
    orderable: false,
    visibility: {
      name: "Categories",
      mode: "always-visible-not-orderable",
    },
    width: 275,
    render: (row) => (
      <TransactionsCategoryColumn
        mode={(row.data?.length ?? 0) > 0 ? "group" : "line"}
        {...row}
      />
    ),
    sortable: "asc",
  };

  if (isItemsOrQuickbooks) {
    info.columns.push({ ...nameColumn, footer: <TransactionsLineFooter /> });
  }

  if (hasCategories) {
    info.columns.push({
      ...categoryColumn,
      footer: isItemsOrQuickbooks ? (
        <TransactionsCategoryFooter />
      ) : (
        <TransactionsLineFooter />
      ),
    });
  }

  if (viewMode === "categories") {
    info.columns.push({
      ...nameColumn,
      footer: <TransactionsCategoryFooter />,
    });
  }

  const costs: LineColumn = {
    id: "costs",
    name: "Costs",
    columns: [
      {
        id: "budget",
        name: <CostsBudgetHeader />,
        width: 205,
        render: (row) => <CostsBudgetColumn {...row} />,
        footer: <CostsBudgetFooter />,
        sortable: true,
        visibility: {
          name: <CostBudgetVisibilityName />,
          mode: "visible",
        },
      },
    ],
    highlight: "info",
  };

  if (hasChanges) {
    costs.columns.push(
      {
        id: "changes",
        name: <CostsChangesHeader />,
        width: 165,
        render: (row) => <CostsChangesColumn {...row} />,
        footer: <CostsChangesFooter />,
        sortable: true,
        visibility: {
          name: "Costs changes",
          mode: "visible",
        },
      },
      {
        id: "revisedBudget",
        name: "Revised budget",
        width: 165,
        render: (row) => <CostsRevisedBudgetColumn {...row} />,
        footer: <CostsRevisedBudgetFooter />,
        message:
          hasOwnersAmount && hasExternalRevisedBudget
            ? "[Cost budget] + [Changes]"
            : "[Budget] + [Changes]",
        sortable: true,
      }
    );
  }

  costs.columns.push({
    id: "actual",
    message: "Total cost of approved bills & receipts",
    name: <CostsActualHeader />,
    width: 185,
    render: (row) => <CostsActualColumn {...row} />,
    footer: <CostsActualFooter />,
    sortable: true,
    visibility: {
      name: "Actual cost to date",
      mode: "visible",
    },
  });

  if (window.BUDGET_UNPAID_BILLS_COLUMN_ENABLED) {
    costs.columns.push({
      id: "unpaid",
      name: "Unpaid bills",
      width: 165,
      message: "Total cost of unpaid bills",
      render: (row) => <CostsUnpaidColumn {...row} />,
      footer: <CostsUnpaidFooter />,
      sortable: true,
    });
  }

  costs.columns.push({
    id: "costsRemaining",
    name: "Budget remaining",
    width: 175,
    render: (row) => <CostsRemainingColumn {...row} />,
    footer: <CostsRemainingFooter />,
    message: <CostsBudgetRemainingTooltip />,
    sortable: true,
  });

  const revenues: LineColumn = {
    id: "revenues",
    name: "Revenue",
    columns: [],
    highlight: "success",
  };

  if (window.BUDGET_MARKUP_COLUMN_ENABLED) {
    revenues.columns.push({
      id: "markup",
      name: "Markup",
      width: 165,
      render: (row) => <RevenuesMarkupColumn {...row} />,
      footer: <EmptyColumn />,
    });
  }

  if (hasOwnersAmount) {
    revenues.columns.push({
      id: "ownersBudget",
      name: <RevenuesOwnersBudgetHeader />,
      width: 225,
      visibility: {
        name: <RevenuesOwnersBudgetVisibilityName />,
        mode: "visible",
      },
      render: (row) => <RevenuesOwnersBudgetColumn {...row} />,
      footer: <RevenuesOwnersBudgetFooter />,
      sortable: true,
    });

    if (hasChanges && hasExternalRevisedBudget) {
      revenues.columns.push(
        {
          id: "revenuesChanges",
          name: <RevenuesChangesHeader />,
          width: 165,
          visibility: {
            name: "Revenue changes",
            mode: "visible",
          },
          render: (row) => <RevenuesChangesColumn {...row} />,
          footer: <RevenuesChangesFooter />,
          sortable: true,
        },
        {
          id: "revenuesRevisedBudget",
          name: "Revised budget",
          width: 165,
          render: (row) => <RevenuesRevisedBudgetColumn {...row} />,
          footer: <RevenuesRevisedBudgetFooter />,
          message: "[Revenue budget] + [Changes]",
          sortable: true,
        }
      );
    }
  }

  revenues.columns.push(
    {
      id: "drawn",
      name: <RevenuesDrawnHeader />,
      width: 165,
      visibility: {
        name: "Draw to date",
        mode: "visible",
      },
      render: (row) => <RevenuesDrawnColumn {...row} />,
      footer: <RevenuesDrawnFooter />,
      message: "Total amount on draft & synced draws",
      sortable: true,
    },
    {
      id: "remaining",
      name: "Draw remaining",
      width: 165,
      message: <RevenuesDrawRemainingTooltip />,
      render: (row) => <RevenuesDrawnRemainingColumn {...row} />,
      footer: <RevenuesDrawnRemainingFooter />,
      sortable: true,
    }
  );

  return [info, costs, revenues];
};

export const LINE_COLUMN_ID_TO_PROP = {
  nameItems: "jobCostMethod.displayName",
  nameQuickbooks: "jobCostMethod.displayName",
  nameCategories: "jobCostMethod.displayName",
  categoryItems: "category.displayName",
  categoryQuickbooks: "category.displayName",
  categoryCategories: "category.displayName",
  budget: "builderAmount",
  changes: "changeAmount",
  revisedBudget: "builderRevisedAmount",
  actual: "spent",
  unpaid: "unpaidBills",
  costsRemaining: "budgetRemaining",
  ownersBudget: "ownersAmount",
  revenuesChanges: "externalChangeAmount",
  revenuesRevisedBudget: "ownersRevisedAmount",
  drawn: "invoicedAmount",
  remaining: "remaining",
};
