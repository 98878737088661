import React, {
  type FocusEventHandler,
  Fragment,
  memo,
  useEffect,
  useState,
} from "react";
import {
  Button,
  CurrencyField,
  Flex,
  Icon,
  Link,
  type TableRow,
  Tag,
  Text,
  Tooltip,
  Wrapper,
} from "@adaptive/design-system";
import { useEvent } from "@adaptive/design-system/hooks";
import { formatCurrency } from "@adaptive/design-system/utils";
import type { StoreCustomerCategoryResponse } from "@api/jobs";
import { useJobPermissions, useJobSettings } from "@src/jobs";
import {
  CURRENCY_FIELD_FORMAT_PROPS,
  CURRENCY_FORMAT,
} from "@src/jobs/constants";
import { AddBudgetLineDialogButton } from "@src/jobs/detail-view/add-budget-line-dialog";
import { useJobInfo } from "@store/jobs";
import { sum } from "@utils/sum";

import { BudgetsCategoriesCombobox } from "../budgets-categories-combobox";

import { useGetRemaining } from "./hooks/use-row-calculations";
import {
  useBudgetsTableActions,
  useBudgetsTableBuilderAmount,
  useBudgetsTableBuilderRevisedAmount,
  useBudgetsTableChangeAmount,
  useBudgetsTableExternalChangeAmount,
  useBudgetsTableInvoicedAmount,
  useBudgetsTableIsBuilderBudgetLocked,
  useBudgetsTableOwnersAmount,
  useBudgetsTableOwnersRevisedAmount,
  useBudgetsTableShouldShowSourceType,
  useBudgetsTableSpent,
  useBudgetsTableUnpaidBills,
  useBudgetsTableViewMode,
} from "./budgets-table-context";
import { getIsDeletable, type Line } from "./lines";

type LinesRow = TableRow<Line>;

type Mode = "line" | "markup" | "group";

export const EmptyColumn = memo(() => null);

EmptyColumn.displayName = "EmptyColumn";

/**
 * Transactions components
 */
export const TransactionsDeleteButton = memo<LinesRow>((row) => {
  const { job } = useJobInfo();

  const isDeletable = getIsDeletable(row);

  const { canManage } = useJobPermissions();

  const { onDeleteBudgetLine } = useBudgetsTableActions();

  const columnsPreventingDeletion = job.change_tracking_enabled
    ? "spent, drawn, or change amounts"
    : "spent or drawn amounts";

  return (
    <Tooltip
      message={
        !canManage
          ? "You don't have permission to do this"
          : isDeletable
            ? ""
            : `Budget lines that have ${columnsPreventingDeletion} cannot be deleted`
      }
    >
      <Button
        size="sm"
        color="neutral"
        variant="ghost"
        onClick={() => onDeleteBudgetLine(row)}
        disabled={!canManage || !isDeletable}
        aria-label={`Remove ${row.jobCostMethod.displayName}`}
        data-testid="budget-remove-line-button"
      >
        <Icon name="trash" />
      </Button>
    </Tooltip>
  );
});

TransactionsDeleteButton.displayName = "TransactionsDeleteButton";

export const TransactionsLineColumn = memo<LinesRow>((row) => {
  const viewMode = useBudgetsTableViewMode();

  const { categoriesEnabled } = useJobSettings();

  const shouldShowSourceType = useBudgetsTableShouldShowSourceType();

  const {
    jobCostMethod: { displayName, parentLabel },
  } = row;

  /** This feature is not yet implemented */
  const isBatchUpdateEnabled = false;

  const hasChild = (row.data ?? []).length > 0;

  if (hasChild && viewMode === "categories") {
    return isBatchUpdateEnabled && row.category?.id ? (
      <Flex gap="md" grow justify="flex-end">
        <Button
          size="sm"
          variant="ghost"
          color="neutral"
          aria-label="Edit category"
        >
          <Icon name="pen" />
        </Button>
        <Button
          size="sm"
          variant="ghost"
          color="neutral"
          aria-label="Delete category"
        >
          <Icon name="trash" />
        </Button>
      </Flex>
    ) : null;
  }

  return (
    <Flex gap="md" width="full" align="center" justify="space-between">
      <Flex direction="column">
        <Text>{displayName}</Text>
        {shouldShowSourceType && viewMode === "items" && (
          <Text size="xs">{row.sourceType}</Text>
        )}
        {parentLabel && viewMode === "items" && (
          <Text size="xs">Parent: {parentLabel}</Text>
        )}
      </Flex>
      {((!categoriesEnabled && !hasChild) || viewMode === "categories") && (
        <TransactionsDeleteButton {...row} />
      )}
    </Flex>
  );
});

TransactionsLineColumn.displayName = "TransactionsLineColumn";

export const TransactionsLineFooter = memo(() => {
  const { categoriesEnabled } = useJobSettings();

  return (
    <Flex align="center" justify="space-between">
      <AddBudgetLineDialogButton />
      {!categoriesEnabled && <Text weight="bold">Item total</Text>}
    </Flex>
  );
});

TransactionsLineFooter.displayName = "TransactionsLineFooter";

export const TransactionsCategoryColumn = memo(
  ({ mode, ...row }: LinesRow & { mode: Mode }) => {
    const viewMode = useBudgetsTableViewMode();

    const { updateCategory } = useBudgetsTableActions();

    const [internalValue, setInternalValue] = useState<
      string | { label: string; value: string }
    >("");

    const onChange = useEvent((categoryId?: string) => {
      if (!categoryId || categoryId === row.category?.id) return;

      updateCategory({ categoryId, budgetLineId: row.id });
      setInternalValue(categoryId);
    });

    const onBlur = useEvent<FocusEventHandler<HTMLInputElement>>((e) => {
      if (e.currentTarget.value !== "" || row.category === null) return;

      updateCategory({ categoryId: "", budgetLineId: row.id });
      setInternalValue("");
    });

    const onAddCategory = useEvent(
      (category: StoreCustomerCategoryResponse) => {
        updateCategory({ categoryId: category.id, budgetLineId: row.id });
        setInternalValue({ value: category.id, label: category.displayName });
      }
    );

    useEffect(() => {
      setInternalValue(
        row.category?.id
          ? { label: row.category.displayName, value: row.category.id }
          : ""
      );
    }, [row.category]);

    if (mode === "group" && viewMode === "categories") {
      return row.category?.displayName ?? "Uncategorized";
    }

    if (mode === "group" && viewMode === "quickbooks") return null;

    return (
      <Flex gap="md" width="full">
        <BudgetsCategoriesCombobox
          value={internalValue}
          onBlur={onBlur}
          onChange={onChange}
          onAddCategory={onAddCategory}
        />
        {mode === "line" && ["items", "quickbooks"].includes(viewMode) && (
          <TransactionsDeleteButton {...row} />
        )}
      </Flex>
    );
  }
);

TransactionsCategoryColumn.displayName = "TransactionsCategoryColumn";

export const TransactionsCategoryFooter = memo(() => (
  <Text weight="bold" align="right">
    Item total
  </Text>
));

TransactionsCategoryFooter.displayName = "TransactionsCategoryFooter";

/**
 * Costs budget components
 */
export const CostsBudgetHeader = memo(() => {
  const { canManage } = useJobPermissions();

  const isBuilderBudgetLocked = useBudgetsTableIsBuilderBudgetLocked();

  const { ownersAmountEnabled } = useJobSettings();

  const { curriedOnOpenOneSchema } = useBudgetsTableActions();

  const { onToggleBudgetLockedStatus } = useBudgetsTableActions();

  return (
    <Wrapper
      when={window.BUDGET_LOCKABLE_ENABLED ?? false}
      render={(children) => (
        <Flex align="center" width="full" gap="md">
          {children}
          <Tooltip
            message={
              canManage
                ? isBuilderBudgetLocked
                  ? "Unlock the initial budget to make edits. These edits will not be reflected in the Revised budget column"
                  : isBuilderBudgetLocked === null
                    ? "Lock in this job’s initial budget to enable revision tracking"
                    : "Lock the Budget column to push changes over to the Revised budget column"
                : "You don't have permission to do this"
            }
            aria-label={
              isBuilderBudgetLocked
                ? "Lock builder’s budget"
                : "Unlock builder’s budget"
            }
          >
            <Button
              size="sm"
              color="neutral"
              variant="ghost"
              onClick={onToggleBudgetLockedStatus}
              disabled={!canManage}
              aria-label={
                isBuilderBudgetLocked
                  ? "Lock builder’s budget"
                  : "Unlock builder’s budget"
              }
            >
              <Icon name={isBuilderBudgetLocked ? "lock" : "unlock"} />
            </Button>
          </Tooltip>
        </Flex>
      )}
    >
      <Flex align="center" width="full" gap="sm">
        <Text weight="bold">
          {ownersAmountEnabled ? "Cost budget" : "Budget"}
        </Text>
        <Tooltip
          message={
            canManage
              ? "Import your cost budget from a spreadsheet"
              : "You don't have permission to do this"
          }
        >
          <Button
            size="sm"
            color="neutral"
            variant="text"
            onClick={curriedOnOpenOneSchema("builderAmount")}
            disabled={!canManage}
            aria-label="Import cost budget"
          >
            <Icon name="arrow-up-from-bracket" />
          </Button>
        </Tooltip>
      </Flex>
    </Wrapper>
  );
});

CostsBudgetHeader.displayName = "CostsBudgetHeader";

export const CostBudgetVisibilityName = memo(() => {
  const { ownersAmountEnabled } = useJobSettings();

  return <>{ownersAmountEnabled ? "Cost budget" : "Budget"}</>;
});

CostBudgetVisibilityName.displayName = "CostBudgetVisibilityName";

export const CostsBudgetColumn = memo<LinesRow>((row) => {
  const { canEditInitialBudget } = useJobPermissions();

  const { curriedOnEditBuildersBudgetChange } = useBudgetsTableActions();

  const isStatic = !canEditInitialBudget || (row.data?.length ?? 0) > 0;

  return isStatic ? (
    <Text align="right">
      {formatCurrency(row.builderAmount, CURRENCY_FORMAT)}
    </Text>
  ) : (
    <CurrencyField
      {...CURRENCY_FIELD_FORMAT_PROPS}
      value={row.builderAmount}
      onChange={curriedOnEditBuildersBudgetChange(row)}
    />
  );
});

CostsBudgetColumn.displayName = "CostsBudgetColumn";

export const CostsBudgetFooter = memo(() => {
  const builderAmount = useBudgetsTableBuilderAmount();

  return (
    <Text align="right" weight="bold" data-testid="budget-total">
      {formatCurrency(builderAmount, CURRENCY_FORMAT)}
    </Text>
  );
});

CostsBudgetFooter.displayName = "CostsBudgetFooter";

/**
 * Costs changes components
 */
export const CostsChangesHeader = () => {
  const { curriedOnSeeChanges } = useBudgetsTableActions();

  return (
    <Link
      as="button"
      type="button"
      onClick={curriedOnSeeChanges()}
      data-testid="changes"
    >
      <Text as="span" size="sm" weight="bold">
        Changes
      </Text>
    </Link>
  );
};

CostsChangesHeader.displayName = "CostsChangesHeader";

export const CostsChangesColumn = memo<LinesRow>((row) => {
  const { curriedOnSeeChanges } = useBudgetsTableActions();

  const isStatic = !row.hasChanges || (row.data?.length ?? 0) > 0;

  if (isStatic) {
    return (
      <Text align="right" size="sm" data-testid="changes">
        {formatCurrency(row.changeAmount ?? 0, CURRENCY_FORMAT)}
      </Text>
    );
  }

  return (
    <Flex justify="flex-end">
      <Link
        as="button"
        type="button"
        onClick={curriedOnSeeChanges(row)}
        variant="success"
        data-testid="changes"
      >
        <Text as="span" size="sm" align="right">
          {formatCurrency(row.changeAmount ?? 0, CURRENCY_FORMAT)}
        </Text>
      </Link>
    </Flex>
  );
});

CostsChangesColumn.displayName = "CostsChangesColumn";

export const CostsChangesFooter = memo(() => {
  const builderChangeAmount = useBudgetsTableChangeAmount();

  return (
    <Text align="right" weight="bold">
      {formatCurrency(builderChangeAmount, CURRENCY_FORMAT)}
    </Text>
  );
});

CostsChangesFooter.displayName = "CostsChangesFooter";

export const CostsRevisedBudgetColumn = memo<LinesRow>((row) => (
  <Text align="right">
    {formatCurrency(row.builderRevisedAmount ?? 0, CURRENCY_FORMAT)}
  </Text>
));

CostsRevisedBudgetColumn.displayName = "CostsRevisedBudgetColumn";

export const CostsRevisedBudgetFooter = memo(() => {
  const builderRevisedAmount = useBudgetsTableBuilderRevisedAmount();

  return (
    <Text align="right" weight="bold">
      {formatCurrency(builderRevisedAmount, CURRENCY_FORMAT)}
    </Text>
  );
});

CostsRevisedBudgetFooter.displayName = "CostsRevisedBudgetFooter";

/**
 * Costs actual components
 */
export const CostsActualHeader = memo(() => {
  const { curriedOnSeeTransactions } = useBudgetsTableActions();

  return (
    <Link as="button" type="button" onClick={curriedOnSeeTransactions()}>
      <Text as="span" weight="bold" size="sm" data-testid="spent-header">
        Actual costs to date
      </Text>
    </Link>
  );
});

CostsActualHeader.displayName = "CostsActualHeader";

export const CostsActualColumn = memo<LinesRow>((row) => {
  const { curriedOnSeeTransactions } = useBudgetsTableActions();

  const amountSpent = formatCurrency(row.spent, CURRENCY_FORMAT);

  const isStatic = !row.spent || (row.data?.length ?? 0) > 0;

  if (isStatic) {
    return (
      <Text align="right" size="sm" data-testid="spent">
        {amountSpent}
      </Text>
    );
  }

  return (
    <Flex justify="flex-end">
      <Link
        as="button"
        type="button"
        onClick={curriedOnSeeTransactions(row)}
        variant="success"
      >
        <Text as="span" size="sm" data-testid="spent">
          {amountSpent}
        </Text>
      </Link>
    </Flex>
  );
});

CostsActualColumn.displayName = "CostsActualColumn";

export const CostsActualFooter = memo(() => {
  const spent = useBudgetsTableSpent();

  return (
    <Text align="right" weight="bold">
      {formatCurrency(spent, CURRENCY_FORMAT)}
    </Text>
  );
});

CostsActualFooter.displayName = "CostsActualFooter";

/**
 * Costs unpaid components
 */
export const CostsUnpaidColumn = memo<LinesRow>((row) => {
  const { curriedOnSeeTransactions } = useBudgetsTableActions();

  const unpaidBills = formatCurrency(row.unpaidBills, CURRENCY_FORMAT);

  const isStatic = !row.unpaidBills || (row.data?.length ?? 0) > 0;

  if (isStatic) {
    return (
      <Text align="right" size="sm">
        {unpaidBills}
      </Text>
    );
  }

  return (
    <Flex justify="flex-end">
      <Link
        as="button"
        type="button"
        onClick={curriedOnSeeTransactions(row)}
        variant="success"
      >
        <Text as="span" size="sm">
          {unpaidBills}
        </Text>
      </Link>
    </Flex>
  );
});

CostsUnpaidColumn.displayName = "CostsUnpaidColumn";

export const CostsUnpaidFooter = memo(() => {
  const unpaidBills = useBudgetsTableUnpaidBills();

  return (
    <Text align="right" weight="bold">
      {formatCurrency(unpaidBills, CURRENCY_FORMAT)}
    </Text>
  );
});

CostsUnpaidFooter.displayName = "CostsUnpaidFooter";

/**
 * Costs remaining components
 */
export const CostsBudgetRemainingTooltip = memo(() => {
  const { changeTrackingEnabled } = useJobSettings();

  return !changeTrackingEnabled
    ? "[Budget] - [Actual costs]"
    : "[Revised budget] - [Actual costs]";
});

CostsBudgetRemainingTooltip.displayName = "CostsBudgetRemainingTooltip";

export const CostsRemainingColumn = memo<LinesRow>((row) => (
  <Text
    align="right"
    color={row.budgetRemaining >= 0 ? "neutral-800" : "warning-200"}
  >
    {formatCurrency(row.budgetRemaining, CURRENCY_FORMAT)}
  </Text>
));

CostsRemainingColumn.displayName = "CostsRemainingColumn";

export const CostsRemainingFooter = memo(() => {
  const spent = useBudgetsTableSpent();

  const builderAmount = useBudgetsTableBuilderAmount();

  const builderRevisedAmount = useBudgetsTableBuilderRevisedAmount();

  const { changeTrackingEnabled } = useJobSettings();

  const preferredBudget = changeTrackingEnabled
    ? builderRevisedAmount
    : builderAmount;

  const remaining = sum(preferredBudget, -spent);

  return (
    <Text
      align="right"
      weight="bold"
      color={remaining >= 0 ? "neutral-800" : "warning-200"}
      data-testid="budget-remaining-total"
    >
      {formatCurrency(remaining, CURRENCY_FORMAT)}
    </Text>
  );
});

CostsRemainingFooter.displayName = "CostsRemainingFooter";

/**
 * Revenues markups components
 */
export const RevenuesMarkupColumn = memo<LinesRow>((row) => {
  const inlineMarkups = row.markups.filter(
    ({ isSeparateLine }) => !isSeparateLine
  );

  const { curriedOnEditWorkflow } = useBudgetsTableActions();

  if (row.expanded) return null;

  return (
    <Flex align="center" gap="sm" justify="space-between">
      <Flex gap="sm" align="center">
        {inlineMarkups.length > 0 && (
          <>
            <Button
              size="sm"
              color="neutral"
              variant="ghost"
              aria-label="Edit inline percent markup"
              onClick={curriedOnEditWorkflow(row)}
            >
              <Icon name="pen" />
            </Button>
          </>
        )}
      </Flex>
    </Flex>
  );
});

RevenuesMarkupColumn.displayName = "RevenuesMarkupColumn";

/**
 * Revenues owners budget components
 */
export const RevenuesOwnersBudgetHeader = memo(() => {
  const { canManage } = useJobPermissions();

  const { ownersAmountEnabled } = useJobSettings();

  const { curriedOnOpenOneSchema } = useBudgetsTableActions();

  return (
    <Flex align="center" gap="md">
      <Flex align="center" width="full" gap="sm">
        <Text weight="bolder" size="sm">
          {ownersAmountEnabled ? "External budget" : "Budget"}
        </Text>
        <Tooltip
          message={
            canManage
              ? "Import your external budget from a spreadsheet"
              : "You don't have permission to do this"
          }
        >
          <Button
            size="sm"
            color="neutral"
            variant="text"
            onClick={curriedOnOpenOneSchema("ownersAmount")}
            disabled={!canManage}
            aria-label="Import external budget"
          >
            <Icon name="arrow-up-from-bracket" />
          </Button>
        </Tooltip>
      </Flex>
    </Flex>
  );
});

RevenuesOwnersBudgetHeader.displayName = "RevenuesOwnersBudgetHeader";

export const RevenuesOwnersBudgetVisibilityName = memo(() => {
  const { ownersAmountEnabled } = useJobSettings();

  return <>{ownersAmountEnabled ? "External budget" : "Budget"}</>;
});

RevenuesOwnersBudgetVisibilityName.displayName =
  "RevenuesOwnersBudgetVisibilityName";

export const RevenuesOwnersBudgetColumn = memo<LinesRow>((row) => {
  const { canManage } = useJobPermissions();

  const { changeTrackingEnabled } = useJobSettings();

  const { curriedOnEditOwnersBudgetChange } = useBudgetsTableActions();

  const isStatic = !canManage || (row.data?.length ?? 0) > 0;

  const amount = isStatic ? (
    <Text align="right">
      {formatCurrency(row.ownersAmount, CURRENCY_FORMAT)}
    </Text>
  ) : (
    <CurrencyField
      {...CURRENCY_FIELD_FORMAT_PROPS}
      value={row.ownersAmount}
      onChange={curriedOnEditOwnersBudgetChange(row)}
    />
  );

  const builderRevisedAmount = row.builderRevisedAmount ?? 0;

  const builderAmount = row.builderAmount ?? 0;

  const preferredBudget = changeTrackingEnabled
    ? builderRevisedAmount
    : builderAmount;

  const difference = Number(
    ((row.ownersAmount * 100) / preferredBudget - 100).toFixed(2)
  );

  const isValidResult =
    !Number.isNaN(difference) && Number.isFinite(difference);

  const hasPercentage =
    isValidResult && difference !== 0 && row.ownersAmount !== 0;

  return (
    <Flex gap="sm" width="full" align="center">
      <Flex justify="flex-end" grow>
        {amount}
      </Flex>
      {hasPercentage && (
        <Tooltip
          as={Flex}
          width="max-content"
          shrink={false}
          message={`${parseFloat(Math.abs(difference).toFixed(1))}% ${
            difference > 0 ? "increase" : "decrease"
          } from cost budget`}
        >
          <Tag size="sm" truncate={{ tooltip: false }}>
            {parseFloat(difference.toFixed(1))}%
          </Tag>
        </Tooltip>
      )}
    </Flex>
  );
});

RevenuesOwnersBudgetColumn.displayName = "RevenuesOwnersBudgetColumn";

export const RevenuesOwnersBudgetFooter = memo(() => {
  const ownersAmount = useBudgetsTableOwnersAmount();

  return (
    <Text align="right" weight="bold">
      {formatCurrency(ownersAmount, CURRENCY_FORMAT)}
    </Text>
  );
});

RevenuesOwnersBudgetFooter.displayName = "RevenuesOwnersBudgetFooter";

/**
 * Revenue changes components
 */
export const RevenuesChangesHeader = () => {
  const { curriedOnSeeChanges } = useBudgetsTableActions();

  return (
    <Link
      as="button"
      type="button"
      onClick={curriedOnSeeChanges()}
      data-testid="changes"
    >
      <Text as="span" size="sm" weight="bold">
        Changes
      </Text>
    </Link>
  );
};

RevenuesChangesHeader.displayName = "RevenuesChangesHeader";

export const RevenuesChangesColumn = memo<LinesRow>((row) => {
  const { curriedOnSeeChanges } = useBudgetsTableActions();

  const isStatic = !row.hasChanges || (row.data?.length ?? 0) > 0;

  if (isStatic) {
    return (
      <Text align="right" size="sm" data-testid="changes">
        {formatCurrency(row.externalChangeAmount ?? 0, CURRENCY_FORMAT)}
      </Text>
    );
  }

  return (
    <Flex justify="flex-end">
      <Link
        as="button"
        type="button"
        onClick={curriedOnSeeChanges(row)}
        variant="success"
        data-testid="changes"
      >
        <Text as="span" size="sm">
          {formatCurrency(row.externalChangeAmount ?? 0, CURRENCY_FORMAT)}
        </Text>
      </Link>
    </Flex>
  );
});

RevenuesChangesColumn.displayName = "RevenuesChangesColumn";

export const RevenuesChangesFooter = memo(() => {
  const externalChangeAmount = useBudgetsTableExternalChangeAmount();

  return (
    <Text align="right" weight="bold">
      {formatCurrency(externalChangeAmount, CURRENCY_FORMAT)}
    </Text>
  );
});

RevenuesChangesFooter.displayName = "RevenuesChangesFooter";

/**
 * Revenue revised budget components
 */
export const RevenuesRevisedBudgetColumn = memo<LinesRow>((row) => (
  <Text align="right">
    {formatCurrency(row.ownersRevisedAmount ?? 0, CURRENCY_FORMAT)}
  </Text>
));

RevenuesRevisedBudgetColumn.displayName = "RevenuesRevisedBudgetColumn";

export const RevenuesRevisedBudgetFooter = memo(() => {
  const totalOwnersRevisedAmount = useBudgetsTableOwnersRevisedAmount();

  return (
    <Text align="right" weight="bold">
      {formatCurrency(totalOwnersRevisedAmount, CURRENCY_FORMAT)}
    </Text>
  );
});

RevenuesRevisedBudgetFooter.displayName = "RevenuesRevisedBudgetFooter";

/**
 * Revenues drawn components
 */

export const RevenuesDrawnHeader = memo(() => {
  const { curriedOnSeeDrawnToDate } = useBudgetsTableActions();

  return (
    <Link
      as="button"
      type="button"
      onClick={curriedOnSeeDrawnToDate()}
      data-testid="drawn-to-date"
    >
      <Text as="span" size="sm" weight="bold">
        Drawn to date
      </Text>
    </Link>
  );
});

RevenuesDrawnHeader.displayName = "RevenuesDrawnHeader";

export const RevenuesDrawnColumn = memo<LinesRow>((row) => {
  const { curriedOnSeeDrawnToDate } = useBudgetsTableActions();

  const invoicedAmount = formatCurrency(row.invoicedAmount, CURRENCY_FORMAT);

  const isStatic = !row.hasInvoices || (row.data?.length ?? 0) > 0;

  if (isStatic) return <Text align="right">{invoicedAmount}</Text>;

  return (
    <Flex justify="flex-end">
      <Link
        as="button"
        type="button"
        onClick={curriedOnSeeDrawnToDate(row)}
        variant="success"
      >
        <Text as="span" size="sm">
          {invoicedAmount}
        </Text>
      </Link>
    </Flex>
  );
});

RevenuesDrawnColumn.displayName = "RevenuesDrawnColumn";

export const RevenuesDrawnFooter = memo(() => {
  const invoicedAmount = useBudgetsTableInvoicedAmount();

  return (
    <Text align="right" weight="bold" data-testid="invoiced">
      {formatCurrency(invoicedAmount, CURRENCY_FORMAT)}
    </Text>
  );
});

RevenuesDrawnFooter.displayName = "RevenuesDrawnFooter";

/**
 * Revenues drawn remaining components
 */
export const RevenuesDrawRemainingTooltip = memo(() => {
  const { changeTrackingEnabled, ownersAmountEnabled } = useJobSettings();

  return ownersAmountEnabled
    ? "[Owner’s budget] - [Drawn to date]"
    : !changeTrackingEnabled
      ? "[Budget] - [Drawn to date]"
      : "[Revised budget] - [Drawn to date]";
});

RevenuesDrawRemainingTooltip.displayName = "RevenuesDrawRemainingTooltip";

export const RevenuesDrawnRemainingColumn = memo<LinesRow>((row) => (
  <Text
    align="right"
    color={row.remaining >= 0 ? "neutral-800" : "warning-200"}
  >
    {formatCurrency(row.remaining, CURRENCY_FORMAT)}
  </Text>
));

RevenuesDrawnRemainingColumn.displayName = "RevenuesDrawnRemainingColumn";

export const RevenuesDrawnRemainingFooter = memo(() => {
  const spent = useBudgetsTableSpent();

  const ownersAmount = useBudgetsTableOwnersAmount();

  const builderAmount = useBudgetsTableBuilderAmount();

  const invoicedAmount = useBudgetsTableInvoicedAmount();

  const ownersRevisedAmount = useBudgetsTableOwnersRevisedAmount();

  const builderRevisedAmount = useBudgetsTableBuilderRevisedAmount();

  const { invoicedRemainingAmount } = useGetRemaining({
    spent,
    ownersAmount,
    builderAmount,
    invoicedAmount,
    ownersRevisedAmount,
    builderRevisedAmount,
  });

  return (
    <Text
      align="right"
      weight="bold"
      color={invoicedRemainingAmount >= 0 ? "neutral-800" : "warning-200"}
    >
      {formatCurrency(invoicedRemainingAmount, CURRENCY_FORMAT)}
    </Text>
  );
});

RevenuesDrawnRemainingFooter.displayName = "RevenuesDrawnRemainingFooter";
