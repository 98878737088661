import React, { useEffect } from "react";
import { useSearchParams } from "react-router";
import {
  Flex,
  Tabs,
  TabsList,
  TabsPanel,
  TabsTab,
} from "@adaptive/design-system";
import { useDeepMemo, useEvent } from "@adaptive/design-system/hooks";
import { isEqual } from "@adaptive/design-system/utils";
import type { ActionType } from "@api/jobs";
import { checkFilters } from "@components/customers-table";
import { MainContent } from "@components/main";
import { useJobPermission } from "@src/jobs";
import { CounterLabel } from "@src/shared/components/counter-label";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import { fetchJobs, resetFilters } from "@store/jobs";
import { useClientInfo } from "@store/user";

import { Tab } from "./tab";

export const ListView = () => {
  const dispatch = useAppDispatch();

  const { realmId } = useClientInfo();

  const { canViewAllJobs } = useJobPermission();

  const [searchParams, setSearchParams] = useSearchParams({ status: "active" });

  const currentStatus = (searchParams.get("status") || "active") as ActionType;

  const { limit, activeJobs, inactiveJobs, activeFilters, inactiveFilters } =
    useAppSelector((state) => state.jobs, isEqual);

  const enhancedActiveFilters = useDeepMemo(
    () => [...activeFilters, { index: "limit", value: limit }],
    [limit, activeFilters]
  );

  const enhancedInactiveFilters = useDeepMemo(
    () => [...inactiveFilters, { index: "limit", value: limit }],
    [limit, inactiveFilters]
  );

  const onTabChange = useEvent((status: string) => {
    setSearchParams({ status });
  });

  useEffect(() => {
    const currentFiltersRealm = [
      ...enhancedActiveFilters,
      ...enhancedInactiveFilters,
    ].reduce(
      (acc, filter) =>
        filter.index === "realm" ? [...acc, filter.value as number] : acc,
      [] as number[]
    );

    const hasChangedRealm =
      realmId &&
      (currentFiltersRealm.length === 0 ||
        currentFiltersRealm.some((realm) => realm != realmId));

    if (hasChangedRealm) dispatch(resetFilters(realmId));
  }, [realmId, dispatch, enhancedActiveFilters, enhancedInactiveFilters]);

  /**
   * We fetch here to get the totals, but we also fetch inside the CustomersTable,
   * so to avoid fetching twice we check if the status is idle.
   */
  const canFetchActive = activeJobs.status === "idle";
  const canFetchInactive = inactiveJobs.status === "idle";

  useEffect(() => {
    if (checkFilters(enhancedActiveFilters) && canFetchActive) {
      dispatch(fetchJobs("active", enhancedActiveFilters));
    }
  }, [enhancedActiveFilters, canFetchActive, dispatch]);

  useEffect(() => {
    if (
      checkFilters(enhancedInactiveFilters) &&
      canViewAllJobs &&
      canFetchInactive
    ) {
      dispatch(fetchJobs("inactive", enhancedInactiveFilters));
    }
  }, [enhancedInactiveFilters, canViewAllJobs, canFetchInactive, dispatch]);

  return (
    <MainContent>
      <Flex
        shrink={false}
        style={{
          /* We need to this calc to compensate margin from MainContent */
          minHeight: "calc(var(--spacing-full) - var(--spacing-4xl))",
        }}
        direction="column"
      >
        <Tabs value={currentStatus} onChange={onTabChange}>
          {canViewAllJobs && (
            <TabsList>
              <TabsTab value="active">
                <CounterLabel
                  label="Active"
                  active={currentStatus === "active"}
                  loading={
                    !activeJobs?.status || activeJobs.status !== "loaded"
                  }
                  counter={activeJobs.total}
                />
              </TabsTab>
              <TabsTab value="inactive">
                <CounterLabel
                  label="Inactive"
                  active={currentStatus === "inactive"}
                  loading={
                    !inactiveJobs?.status || inactiveJobs.status !== "loaded"
                  }
                  counter={inactiveJobs.total}
                />
              </TabsTab>
            </TabsList>
          )}
          <TabsPanel>
            <Tab action={currentStatus} />
          </TabsPanel>
        </Tabs>
      </Flex>
    </MainContent>
  );
};
