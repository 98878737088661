import React, { memo, type PropsWithChildren, type ReactNode } from "react";

type Props = PropsWithChildren<{
  when: boolean;
  render: (children: ReactNode) => JSX.Element;
}>;

export const Wrapper = memo(({ when, render, children }: Props) =>
  when ? render(children) : <>{children}</>
);

Wrapper.displayName = "Wrapper";
