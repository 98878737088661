import React, { useEffect, useMemo, useState } from "react";
import { Link as ReactRouterLink, useSearchParams } from "react-router";
import { Button, Flex, Link, Loader } from "@adaptive/design-system";
import { useEvent } from "@adaptive/design-system/hooks";
import { login } from "@api/auth";
import { Layout } from "@components/layout";

import { LoginForm, type LoginFormInfo } from "./login-form";

// For now, we don't redirect any paths to the mobile app
// TODO: Add paths to redirect to mobile app
// TODO: Only enable redirection for mobile devices
const mobileSupportedPaths: string[] = [];

const useMobileRedirect = () => {
  const [params] = useSearchParams();
  const next = params?.get("next") || "";
  const isMobileSupportedPath = mobileSupportedPaths.some((path) =>
    next.startsWith(path)
  );

  if (isMobileSupportedPath) {
    window.location.href = window.MOBILE_APP_URL;
  } else {
    document
      .querySelector("meta[name=viewport]")
      ?.setAttribute("content", "width=1366, initial-scale=0.1");
  }
};

export const Login = () => {
  const [isInvalidToken, setIsInvalidToken] = useState(false);

  const [formInfo, setFormInfo] = useState<LoginFormInfo>({
    email: "",
    isValid: false,
    password: "",
    hasError: false,
    isSubmitting: false,
  });

  const [params] = useSearchParams();

  const nextParam = params.get("next") || "";

  const nextUrl = useMemo(() => {
    const url = new URL(
      decodeURIComponent(`${window.location.origin}${nextParam}`)
    );
    url.searchParams.delete("_branch_match_id");
    url.searchParams.delete("_branch_referrer");
    return url;
  }, [nextParam]);

  const next = nextUrl.pathname;

  const token = nextUrl.searchParams.get("token") || "";

  const onSuccess = useEvent(() => {
    window.location.replace(next);
  });

  useMobileRedirect();

  useEffect(() => {
    if (!token) return;

    nextUrl.searchParams.delete("token");

    login({ token })
      .then(() => window.location.replace(nextUrl))
      .catch(() => setIsInvalidToken(true));
  }, [onSuccess, token, nextUrl]);

  if (token && !isInvalidToken) return <Loader position="fixed" />;

  if (next.startsWith("/404") || isInvalidToken) {
    return (
      <Layout
        title=""
        subtitle="We're sorry, the page you requested could not be found. Please go back to the login or contact us."
      >
        <Button as={ReactRouterLink} to="/accounts/login" replace>
          Go to login
        </Button>
      </Layout>
    );
  }

  return (
    <Layout title="Sign in">
      <Flex gap="xl" direction="column">
        <LoginForm id="login" onSuccess={onSuccess} onChange={setFormInfo} />
        <Flex gap="4xl" direction="column">
          <Button
            type="submit"
            form="login"
            data-testid="login"
            block
            disabled={
              formInfo.isSubmitting || !formInfo.isValid || formInfo.hasError
            }
          >
            Sign in
          </Button>
          <Flex justify="center">
            <Link
              as={ReactRouterLink}
              to={`/accounts/forgot-password${
                formInfo.email.length ? `?email=${formInfo.email}` : ""
              }`}
            >
              I forgot my password
            </Link>
          </Flex>
        </Flex>
      </Flex>
    </Layout>
  );
};
