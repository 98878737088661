import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useLocation,
  useMatch,
  useNavigate,
  useSearchParams,
} from "react-router";
import {
  Button,
  Flex,
  Tabs,
  TabsList,
  TabsPanel,
  TabsTab,
} from "@adaptive/design-system";
import {
  useDeepMemo,
  useEvent,
  usePagination,
} from "@adaptive/design-system/hooks";
import { CounterLabel } from "@components/counter-label";
import { Main, MainContent, MainHeader, MainTitle } from "@components/main";
import { defaultArrayFormatter } from "@components/table-filter/formatters";
import { useTableFilters } from "@components/table-filter/table-filter-hooks";
import { usePurchaseOrderDrawer } from "@src/shared/store/ui";
import {
  fetchClosedPurchaseOrders,
  fetchOpenPurchaseOrders,
  setNeedsRefresh,
} from "@store/purchaseOrderListSlice";
import { BasePermissions, useClientInfo, useUserInfo } from "@store/user";
import * as analytics from "@utils/analytics";

import { PurchaseOrdersContent } from "./purchase-orders-content";

const formatter = (realmId, selections) => {
  const query = defaultArrayFormatter(realmId, selections);

  return query.map((item) =>
    item.dataIndex === "po_type" ? { ...item, dataIndex: "type" } : item
  );
};

const isLoading = (status) => ["idle", "pending"].includes(status.loading);

const PurchaseOrder = () => {
  const match = useMatch("/purchase-orders/:purchaseOrderId");

  const { search } = useLocation();

  const purchaseOrderId = match?.params.purchaseOrderId;

  const navigate = useNavigate();

  const { show } = usePurchaseOrderDrawer({
    onClose: () => navigate(`/purchase-orders${search}`),
  });

  useEffect(() => {
    if (purchaseOrderId) {
      show({ id: purchaseOrderId, caller: "purchase-orders-page" });
    }
  }, [purchaseOrderId, show]);

  return null;
};

export const PurchaseOrders = () => {
  const dispatch = useDispatch();

  const { realmId } = useClientInfo();

  const [searchParams, setSearchParams] = useSearchParams({ status: "open" });

  const tab = searchParams.get("status") || "open";

  const { show } = usePurchaseOrderDrawer();

  const openPagination = usePagination({
    perPageVariant: "lg",
    localStorageKey: "purchase-orders-table-limit",
  });

  const closedPagination = usePagination({
    perPageVariant: "lg",
    localStorageKey: "purchase-orders-table-limit",
  });

  const { hasPermission } = useUserInfo();

  const [order, setOrder] = useState("created_at");

  const [selected, setSelected] = useState([]);

  const open = useSelector(
    (state) => state.purchaseOrderList.openPurchaseOrders
  );

  const closed = useSelector(
    (state) => state.purchaseOrderList.closedPurchaseOrders
  );

  const { filters, setFilters, rawFilters } = useTableFilters({
    storageKey: "purchase-orders",
    formatter,
  });

  const openFilters = useDeepMemo(
    () => [
      ...filters,
      { dataIndex: "offset", value: openPagination.offset },
      { dataIndex: "limit", value: openPagination.perPage },
      { dataIndex: "ordering", value: order },
    ],
    [order, filters, openPagination.offset, openPagination.perPage]
  );

  const closedFilters = useDeepMemo(
    () => [
      ...filters,
      { dataIndex: "offset", value: closedPagination.offset },
      { dataIndex: "limit", value: closedPagination.perPage },
      { dataIndex: "ordering", value: order },
    ],
    [order, filters, closedPagination.offset, closedPagination.perPage]
  );

  const onFiltersChange = useEvent((filters) => {
    setSelected([]);
    setFilters(filters);
    openPagination.setPage(0);
    closedPagination.setPage(0);
  });

  const onPerPageChange = useEvent((perPage) => {
    openPagination.setPage(0);
    openPagination.setPerPage(perPage);
    closedPagination.setPage(0);
    closedPagination.setPerPage(perPage);
    analytics.track("perPageLimitChange", {
      location: "purchase-orders-table",
      limit: perPage,
    });
  });

  useEffect(() => {
    dispatch(setNeedsRefresh({ status: "open", refresh: true }));
  }, [openFilters, dispatch]);

  useEffect(() => {
    if (!realmId || !open.needsRefresh) return;

    dispatch(fetchOpenPurchaseOrders(openFilters));
  }, [open.needsRefresh, openFilters, dispatch, realmId]);

  useEffect(() => {
    dispatch(setNeedsRefresh({ status: "closed", refresh: true }));
  }, [closedFilters, dispatch]);

  useEffect(() => {
    if (!realmId || !closed.needsRefresh) return;

    dispatch(fetchClosedPurchaseOrders(closedFilters));
  }, [closed.needsRefresh, closedFilters, dispatch, realmId]);

  return (
    <>
      <Main>
        <MainHeader>
          <Flex align="center" gap="xl">
            <Flex direction="column" grow>
              <MainTitle>Purchase orders</MainTitle>
            </Flex>
            {hasPermission(BasePermissions.ADD_PO) ? (
              <Button
                onClick={() => show({ caller: "purchase-orders-page" })}
                data-testid="new-purchase-order-button"
              >
                New purchase order
              </Button>
            ) : null}
          </Flex>
        </MainHeader>

        <MainContent>
          <Flex
            shrink={false}
            style={{
              /* We need to this calc to compensate margin from MainContent */
              minHeight: "calc(var(--spacing-full) - var(--spacing-4xl))",
            }}
            direction="column"
          >
            <Tabs
              value={tab}
              onChange={(status) => setSearchParams({ status })}
            >
              <TabsList>
                <TabsTab value="open">
                  <CounterLabel
                    label="Opened"
                    active={tab === "open"}
                    counter={open.total}
                    loading={isLoading(open)}
                  />
                </TabsTab>
                <TabsTab value="closed">
                  <CounterLabel
                    label="Closed"
                    active={tab === "closed"}
                    counter={closed.total}
                    loading={isLoading(closed)}
                  />
                </TabsTab>
              </TabsList>
              <TabsPanel>
                <PurchaseOrdersContent
                  data={
                    tab === "open" ? open.purchaseOrders : closed.purchaseOrders
                  }
                  total={tab === "open" ? open.total : closed.total}
                  status={tab === "open" ? "Open" : "Closed"}
                  isError={tab === "open" ? open.isError : closed.isError}
                  loading={isLoading(tab === "open" ? open : closed)}
                  order={order}
                  filters={rawFilters}
                  pagination={
                    tab === "open" ? openPagination : closedPagination
                  }
                  onOrderChange={setOrder}
                  onFiltersChange={onFiltersChange}
                  onPerPageChange={onPerPageChange}
                  selected={selected}
                  setSelected={setSelected}
                />
              </TabsPanel>
            </Tabs>
          </Flex>
        </MainContent>
      </Main>
      <PurchaseOrder />
    </>
  );
};
