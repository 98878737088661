import React, {
  type ChangeEventHandler,
  type ComponentPropsWithoutRef,
  type ComponentRef,
  type FocusEventHandler,
  type FormEventHandler,
  forwardRef,
  type KeyboardEvent,
  type KeyboardEventHandler,
  type LegacyRef,
  type MouseEventHandler,
  type ReactNode,
  useCallback,
  useDeferredValue,
  useEffect,
  useId,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import cn from "clsx";

import { useEvent } from "../../hooks/use-event";
import {
  type ResponsiveProp,
  useResponsiveProp,
} from "../../hooks/use-responsive-prop";
import { isEqual } from "../../utils/is-equal";
import { isOverflowing } from "../../utils/is-overflowing";
import { mergeRefs } from "../../utils/merge-refs";
import { suffixify } from "../../utils/suffixify";
import { FieldMessage, type FieldMessageProps } from "../field-message";
import { Label } from "../label";
import { Loader } from "../loader";
import { useProvider } from "../provider/provider-context";
import { Tooltip } from "../tooltip";

import styles from "./text-field.module.css";

type DefaultComponent = "input";

export type Ref = ComponentRef<DefaultComponent>;

type Props = Omit<
  ComponentPropsWithoutRef<DefaultComponent>,
  "prefix" | "size" | "onChange" | "onInput" | "value" | "onKeyDown"
> & {
  size?: ResponsiveProp<"sm" | "md">;
  value?: string;
  label?: ReactNode;
  align?: "left" | "right";
  prefix?: ReactNode;
  suffix?: ReactNode;
  loading?: boolean | string;
  /**
   * Since internally for React onInput and onChange are the same
   * we change onInput type to follow the same onChange type in
   * that way we have two ways to get the input value, one with
   * onChange that sends only the value and another with onInput
   * that sends the event.
   */
  onInput?: ChangeEventHandler<Ref>;
  onChange?: (value: string) => void;
  onKeyDown?: (event: KeyboardEvent<Ref>) => boolean | void;
  changeMode?: "lazy" | "normal";
  addonAfter?: ReactNode;
  addonBefore?: ReactNode;
  hintMessage?: ReactNode;
  errorMessage?: ReactNode;
  containerRef?: LegacyRef<HTMLDivElement>;
  helperMessage?: ReactNode;
  "data-testid"?: string;
  warningMessage?: ReactNode;
  messageVariant?: FieldMessageProps["variant"];
  renderBeforeInput?: () => ReactNode;
  triggerChangeOnFocusedUnmount?: boolean;
};

const DEFAULT_TOOLTIP = { message: "", isVisible: false };

export const TextField = forwardRef<Ref, Props>(
  (
    {
      id,
      size: rawSize,
      label,
      align = "left",
      value,
      style,
      prefix,
      suffix,
      onBlur,
      onInput,
      onFocus,
      loading,
      required,
      disabled,
      onChange,
      autoFocus,
      className,
      onInvalid,
      onKeyDown,
      changeMode = "normal",
      addonAfter,
      placeholder,
      addonBefore,
      hintMessage,
      onMouseEnter,
      onMouseLeave,
      errorMessage,
      containerRef,
      helperMessage,
      "data-testid": testId,
      messageVariant = "relative",
      warningMessage,
      "aria-labelledby": labelledBy,
      renderBeforeInput,
      triggerChangeOnFocusedUnmount = true,
      ...props
    },
    ref
  ) => {
    const size = useResponsiveProp(rawSize, "md");

    const internalId = useId();

    const enhancedId = id ?? internalId;

    const internalRef = useRef<Ref>(null);

    const previousValueRef = useRef(
      value !== undefined && value !== null ? String(value) : ""
    );

    const [tooltip, setTooltip] = useState(DEFAULT_TOOLTIP);

    const [isFocused, setIsFocused] = useState(false);

    const [internalValue, setInternalValue] = useState(value ?? "");

    const { autoFocus: providerAutoFocus } = useProvider();

    const enhancedAutoFocus = autoFocus && providerAutoFocus;

    const enhancedValue = useMemo(() => {
      if (loading && value !== undefined && value !== null) return "";

      if (changeMode === "lazy") return internalValue;

      return value;
    }, [changeMode, internalValue, loading, value]);

    const deferredValue = useDeferredValue(enhancedValue);

    /**
     * Since we need to use `deferredValue` to avoid some flickers when
     * `changeMode` is `lazy` but `deferredValue` loses the cursor position,
     * we solve it by changing the current value reference based on focus
     */
    const latestValue = isFocused ? enhancedValue : deferredValue;

    const enhancedSuffix = loading ? <Loader /> : suffix;

    const enhancedPlaceholder = loading
      ? typeof loading === "string"
        ? loading
        : "Loading..."
      : placeholder;

    const showTooltip = useCallback(
      (el: Ref) =>
        setTooltip((prevTooltip) => {
          const isVisible = el.value && isOverflowing(el);

          if (isVisible) {
            return { message: el.value, isVisible: isOverflowing(el) };
          }

          return isEqual(prevTooltip, DEFAULT_TOOLTIP)
            ? prevTooltip
            : DEFAULT_TOOLTIP;
        }),
      []
    );

    const hideTooltip = useCallback(() => {
      setTooltip((prevTooltip) =>
        isEqual(prevTooltip, DEFAULT_TOOLTIP) ? prevTooltip : DEFAULT_TOOLTIP
      );
    }, []);

    const internalOnChange = useEvent((value: string) => {
      onChange?.(value);
    });

    const enhancedOnChange = useEvent<ChangeEventHandler<Ref>>((e) => {
      const value = e.target.value;
      previousValueRef.current = value;
      onInput?.(e);
      setInternalValue(value);
      changeMode === "normal" && internalOnChange(value);
    });

    const enhancedOnInvalid = useEvent<FormEventHandler<Ref>>((e) => {
      e.preventDefault();
      onInvalid?.(e);
    });

    const enhancedOnMouseEnter = useEvent<MouseEventHandler<Ref>>((e) => {
      if (document.activeElement !== e.currentTarget) {
        showTooltip(e.currentTarget);
      }

      onMouseEnter?.(e);
    });

    const enhancedOnKeyDown = useEvent<KeyboardEventHandler<Ref>>((e) => {
      const isChildOfForm = e.currentTarget.closest("form") !== null;
      const preventAutoBlurFocus = onKeyDown?.(e) === false;

      if (!preventAutoBlurFocus && !isChildOfForm && e.key === "Enter") {
        internalRef.current?.blur();
        internalRef.current?.focus();
      }
    });

    const focusInput = useEvent(() => {
      internalRef.current?.focus();
    });

    const resetScrollLeft = useEvent(() => {
      requestAnimationFrame(() => {
        if (internalRef.current) {
          internalRef.current.scrollLeft = 0;
        }
      });
    });

    const enhancedOnMouseLeave = useEvent<MouseEventHandler<Ref>>((e) => {
      hideTooltip();
      resetScrollLeft();
      onMouseLeave?.(e);
    });

    const enhancedOnFocus = useEvent<FocusEventHandler<Ref>>((e) => {
      hideTooltip();
      setIsFocused(true);
      onFocus?.(e);
    });

    const enhancedOnBlur = useEvent<FocusEventHandler<Ref>>((e) => {
      onBlur?.(e);
      setIsFocused(false);
      changeMode === "lazy" && internalOnChange(e.target.value);
    });

    /**
     * Remove input value when is loading, we do it programmatically
     * because we want to make it work for non-controlled inputs
     */
    useEffect(() => {
      if (internalRef.current) {
        internalRef.current.value = loading ? "" : previousValueRef.current;
      }
    }, [loading]);

    /**
     * Workaround to set `autofocus` attribute since React ignores it
     */
    useEffect(() => {
      if (internalRef.current) {
        if (enhancedAutoFocus) {
          internalRef.current.setAttribute("autofocus", "");
        } else {
          internalRef.current.removeAttribute("autofocus");
        }
      }
    }, [enhancedAutoFocus]);

    useEffect(() => {
      if (changeMode === "lazy") setInternalValue(value ?? "");
    }, [value, changeMode]);

    useLayoutEffect(() => {
      const el = internalRef.current;

      return () => {
        if (!el || !triggerChangeOnFocusedUnmount) return;

        if (document.activeElement === el) internalOnChange(el.value);
      };
    }, [internalOnChange, triggerChangeOnFocusedUnmount]);

    return (
      <div
        style={style}
        aria-busy={!!loading}
        className={cn(className, styles["wrapper"], {
          [styles["-error"]]: errorMessage,
          [styles["-prefix"]]: prefix,
          [styles["-suffix"]]: enhancedSuffix,
          [styles[`-${size}`]]: size,
          [styles[`-${align}`]]: align,
          [styles["-disabled"]]: disabled,
          [styles["-addon-after"]]: addonAfter,
          [styles["-addon-before"]]: addonBefore,
        })}
        data-testid={suffixify(testId, "wrapper")}
        onMouseLeave={resetScrollLeft}
      >
        {label && (
          <Label
            id={suffixify(enhancedId, "label")}
            htmlFor={enhancedId}
            variant={errorMessage ? "error" : "neutral"}
            required={required}
            hintMessage={hintMessage}
            data-testid={suffixify(testId, "label")}
          >
            {label}
          </Label>
        )}
        <div
          ref={containerRef}
          onClick={focusInput}
          className={styles["container"]}
        >
          {addonBefore && (
            <div
              className={cn(styles["addon"], styles["-before"])}
              data-testid={suffixify(testId, "addon-before")}
            >
              {addonBefore}
            </div>
          )}
          <Tooltip
            as="div"
            message={tooltip.isVisible ? tooltip.message : undefined}
            className={styles["inner"]}
          >
            {prefix && (
              <div
                className={cn(styles["affix"], styles["-prefix"])}
                data-testid={suffixify(testId, "prefix")}
              >
                {prefix}
              </div>
            )}

            <div className={styles["deep"]}>
              {renderBeforeInput?.()}

              <input
                id={enhancedId}
                ref={mergeRefs(internalRef, ref)}
                value={latestValue}
                onBlur={enhancedOnBlur}
                onFocus={enhancedOnFocus}
                readOnly={loading === true}
                required={required}
                disabled={disabled}
                onChange={enhancedOnChange}
                autoFocus={enhancedAutoFocus}
                onInvalid={enhancedOnInvalid}
                className={styles["input"]}
                onKeyDown={enhancedOnKeyDown}
                spellCheck={false}
                data-testid={testId}
                placeholder={enhancedPlaceholder}
                onMouseEnter={enhancedOnMouseEnter}
                onMouseLeave={enhancedOnMouseLeave}
                autoComplete={`${internalId}-off`}
                aria-invalid={!!errorMessage}
                aria-required={required}
                aria-labelledby={labelledBy ?? suffixify(enhancedId, "label")}
                aria-describedby={
                  errorMessage || warningMessage || helperMessage
                    ? suffixify(enhancedId, "message")
                    : undefined
                }
                {...props}
              />
            </div>

            {enhancedSuffix && (
              <div
                className={cn(styles["affix"], styles["-suffix"])}
                data-testid={suffixify(testId, "suffix")}
              >
                {enhancedSuffix}
              </div>
            )}
          </Tooltip>
          {addonAfter && (
            <div
              className={cn(styles["addon"], styles["-after"])}
              data-testid={suffixify(testId, "addon-after")}
            >
              {addonAfter}
            </div>
          )}
        </div>
        <FieldMessage
          id={suffixify(enhancedId, "message")}
          show={!!(errorMessage || warningMessage || helperMessage)}
          color={
            errorMessage ? "error" : warningMessage ? "warning" : "neutral"
          }
          variant={messageVariant}
          data-testid={suffixify(testId, "message")}
        >
          {errorMessage || warningMessage || helperMessage}
        </FieldMessage>
      </div>
    );
  }
);

TextField.displayName = "TextField";
