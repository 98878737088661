import React, {
  type PropsWithChildren,
  useEffect,
  useMemo,
  useState,
  useTransition,
} from "react";
import { useLocation, useNavigate, useParams } from "react-router";
import { useDeepMemo, useEvent } from "@adaptive/design-system/hooks";
import { isEqual } from "@adaptive/design-system/utils";
import type { BillList, Status } from "@src/bills/types";
import {
  billIdSelector,
  getBillsByStatus,
  isLoadingBillSelector,
} from "@src/bills/utils";
import {
  fetchBillsByStatus,
  updateOffset,
} from "@src/shared/store/billListSlice";
import { useAppDispatch, useAppSelector } from "@store/hooks";
import { selectRealmId } from "@store/user/selectors-memoized";

import { CycleContext } from "./cycle-context";

export const CycleProvider = ({ children }: PropsWithChildren) => {
  const navigate = useNavigate();

  const dispatch = useAppDispatch();

  const { state: locationState } = useLocation();

  const [isPending, startTransition] = useTransition();

  const transactionId = useAppSelector(billIdSelector);

  const urlTransactionId = useParams().id;

  const isLoadingTransaction = useAppSelector(isLoadingBillSelector);

  const isSwitchingTransaction =
    urlTransactionId !== "create" && urlTransactionId != transactionId;

  const [status, setStatus] = useState<Status | "">("");

  const [isLoading, setIsLoading] = useState(false);

  const enhancedIsLoading =
    isLoading || isPending || isLoadingTransaction || isSwitchingTransaction;

  const { data, limit, page, total, filters } = useAppSelector((state) => {
    if (!status) {
      return {
        data: [],
        page: 0,
        limit: 0,
        total: 0,
        filters: [],
      };
    }

    const realmId = selectRealmId(state);

    const { filter, ...lists } = state.billList as BillList;

    const currentList = getBillsByStatus(lists, status);

    const query = [
      ...filter.query,
      ...currentList.filter.query.filter((item) => item.dataIndex !== "realm"),
      { dataIndex: "realm", value: realmId },
    ];

    const offset = Number(
      query.find((item) => item.dataIndex === "offset")?.value ?? 0
    );

    const limit = Number(
      query.find((item) => item.dataIndex === "limit")?.value ?? 10
    );

    return {
      data: currentList.bills,
      page: offset / limit,
      limit,
      total: currentList.total,
      offset,
      filters: query,
    };
  }, isEqual);

  const index = useDeepMemo(
    () => data.findIndex((item) => item.id === transactionId),
    [transactionId, data]
  );

  const current = useMemo(() => index + 1 + page * limit, [index, limit, page]);

  const enable = useEvent((status) => setStatus(status));

  const disable = useEvent(() => setStatus(""));

  const next = useEvent(async () => {
    startTransition(() => setIsLoading(true));

    try {
      let nextIndex = index + 1;

      const { count, results } = await dispatch(
        fetchBillsByStatus({ status, filters })
      );

      if (count < total) {
        nextIndex -= 1;
      }

      let nextData = results;

      if (nextIndex < nextData.length) {
        navigate(`/bills/${nextData[nextIndex].id}`, { state: locationState });
      } else {
        let nextOffset = (page + 1) * limit;

        if (nextOffset > total) {
          nextIndex = 0;
          nextOffset = 0;
        }

        if (nextOffset < total) {
          dispatch(updateOffset({ status, value: nextOffset }));

          const { results } = await dispatch(
            fetchBillsByStatus({
              status,
              filters: [
                ...filters.filter((item) => item.dataIndex !== "offset"),
                { dataIndex: "offset", value: nextOffset },
              ],
            })
          );

          nextData = results;
        }

        navigate(`/bills/${nextData[0].id}`, { state: locationState });
      }

      return true;
    } catch (e) {
      return false;
    } finally {
      startTransition(() => setIsLoading(false));
    }
  });

  const previous = useEvent(async () => {
    startTransition(() => setIsLoading(true));

    try {
      const nextIndex = index - 1;

      const { results } = await dispatch(
        fetchBillsByStatus({ status, filters })
      );

      let nextData = results;

      if (nextIndex > -1) {
        navigate(`/bills/${nextData[nextIndex].id}`, { state: locationState });
      } else {
        let nextOffset = (page - 1) * limit;

        const shouldJumpToLastPage = nextOffset < 0;

        if (shouldJumpToLastPage) {
          nextOffset = Math.floor(total / limit) * limit;
        }

        if (nextOffset < total) {
          dispatch(updateOffset({ status, value: nextOffset }));

          const { results } = await dispatch(
            fetchBillsByStatus({
              status,
              filters: [
                ...filters.filter((item) => item.dataIndex !== "offset"),
                { dataIndex: "offset", value: nextOffset },
              ],
            })
          );

          nextData = results;
        }

        navigate(
          `/bills/${
            nextData[shouldJumpToLastPage ? nextData.length - 1 : limit - 1].id
          }`,
          { state: locationState }
        );
      }

      return true;
    } catch (e) {
      return false;
    } finally {
      startTransition(() => setIsLoading(false));
    }
  });

  /**
   * Disable navigation when the transaction not belongs to current
   * collection query status.
   */
  useEffect(() => {
    if (index === -1 && transactionId && !enhancedIsLoading) disable();
  }, [index, disable, transactionId, enhancedIsLoading]);

  return (
    <CycleContext.Provider
      value={{
        next,
        total,
        enable,
        status,
        current,
        disable,
        previous,
        isLoading: enhancedIsLoading,
        hasNavigation: current < total || current > 1,
      }}
    >
      {children}
    </CycleContext.Provider>
  );
};
