import React, {
  type ComponentPropsWithoutRef,
  type ComponentRef,
  type FocusEventHandler,
  type ForwardedRef,
  forwardRef,
  type KeyboardEventHandler,
  useEffect,
  useId,
  useRef,
  useState,
} from "react";
import {
  Popover,
  type PopoverStoreState,
  usePopoverStore,
} from "@ariakit/react";
import { useRifm } from "rifm";

import {
  FORMATTED_DATE_LENGTH,
  FORMATTED_RANGE_DATE_LENGTH,
} from "../../constants";
import { useDebouncedValue } from "../../hooks/use-debounced-value";
import { useEvent } from "../../hooks/use-event";
import { useOnInteractOutside } from "../../hooks/use-on-interact-outside";
import { formatDate } from "../../utils/format-date";
import { mergeRefs } from "../../utils/merge-refs";
import { parseDate } from "../../utils/parse-date";
import { setSelectionRange } from "../../utils/set-selection-range";
import { suffixify } from "../../utils/suffixify";
import { DatePicker } from "../date-picker";
import {
  type DatePickerMode,
  type DatePickerProps,
  type DatePickerRangeValue,
  type DatePickerRef,
  type DatePickerSingleValue,
} from "../date-picker/date-picker";
import { Icon } from "../icon";
import { useProvider } from "../provider/provider-context";
import { TextField } from "../text-field";

import styles from "./date-field.module.css";

type Ref = ComponentRef<typeof TextField>;

export type DateFieldProps<Mode extends DatePickerMode> = Omit<
  ComponentPropsWithoutRef<typeof TextField>,
  | "value"
  | "prefix"
  | "suffix"
  | "onBlur"
  | "onChange"
  | "addonAfter"
  | "addonBefore"
  | "changeMode"
  | "triggerChangeOnFocusedUnmount"
> &
  DatePickerProps<Mode> & {
    flip?: boolean;
    portal?: boolean;
    onBlur?: () => void;
    placement?: PopoverStoreState["placement"];
  };

const SLASH_INDEXES = [3, 6, 16, 19];

const ONLY_ONE_SLASH_REGEX = /\/{2,}/g;

const CURSOR_JUMP_POSITIONS = [2, 4, 5, 7, 14, 17, 20];

const adjustDate = (str: string) => {
  const slices = str.split("");

  if (slices[0] && slices[1] === "/") {
    return `0${slices[0]}`;
  } else if (slices[3] && slices[4] === "/") {
    return `${str.slice(0, -2)}0${slices[3]}`;
  } else if (slices[13] && slices[14] === "/") {
    return `${str.slice(0, -2)}0${slices[13]}`;
  } else if (slices[16] && slices[17] === "/") {
    return `${str.slice(0, -2)}0${slices[16]}`;
  }

  return str;
};

const DateField = <Mode extends DatePickerMode = "single">(
  {
    id,
    mode,
    size,
    flip = false,
    value,
    portal = false,
    onBlur,
    toDate,
    onFocus,
    onChange,
    fromDate,
    autoFocus = false,
    placement = "bottom-start",
    onKeyDown,
    errorMessage,
    helperMessage,
    warningMessage,
    ...props
  }: DateFieldProps<Mode>,
  ref: ForwardedRef<Ref>
) => {
  const internalId = useId();
  const enhancedId = id ?? internalId;

  const popoverRef = useRef<HTMLDivElement>(null);
  const internalRef = useRef<Ref>(null);
  const datePickerRef = useRef<DatePickerRef>(null);

  const { autoFocus: providerAutoFocus } = useProvider();

  const enhancedAutoFocus = autoFocus && providerAutoFocus;
  const [isFocused, setIsFocused] = useState(false);
  const [maskedValue, setMaskedValue] = useState("");
  const [selectedDate, setSelectedDate] = useState<
    DatePickerSingleValue | DatePickerRangeValue | undefined
  >(
    value ?? (mode === "range" ? { from: undefined, to: undefined } : undefined)
  );

  const popoverStore = usePopoverStore({ open: isFocused, placement });

  const [debouncedIsFocused] = useDebouncedValue(isFocused, 100);

  const onDateChange = useEvent(
    (value: DatePickerSingleValue | DatePickerRangeValue) => {
      setIsFocused(false);
      setSelectedDate(value);

      if (mode === "range") {
        (onChange as DateFieldProps<"range">["onChange"])?.(
          value as DatePickerRangeValue
        );
      } else {
        (onChange as DateFieldProps<"single">["onChange"])?.(
          value as DatePickerSingleValue
        );
      }
    }
  );

  const enhancedOnChange = useEvent((value: string) => {
    const enhancedValue = formatDate(value);
    const parsedDate = parseDate(enhancedValue);

    setMaskedValue(enhancedValue);

    if (!parsedDate) return;

    if (
      mode === "range" &&
      (parsedDate as DatePickerRangeValue).from &&
      (parsedDate as DatePickerRangeValue).to &&
      enhancedValue.length === FORMATTED_RANGE_DATE_LENGTH
    ) {
      setSelectedDate(parsedDate as DatePickerRangeValue);
      (onChange as DateFieldProps<"range">["onChange"])?.(
        parsedDate as DatePickerRangeValue
      );
    } else if (
      [undefined, "single"].includes(mode) &&
      enhancedValue.length === FORMATTED_DATE_LENGTH
    ) {
      setSelectedDate(parsedDate);
      (onChange as DateFieldProps<"single">["onChange"])?.(
        parsedDate as DatePickerSingleValue
      );
    }
  });

  const enhancedOnFocus = useEvent<FocusEventHandler<Ref>>((e) => {
    onFocus?.(e);
    setIsFocused(true);
  });

  const enhancedOnKeyDown = useEvent<KeyboardEventHandler<Ref>>((e) => {
    onKeyDown?.(e);

    if (e.code === "Tab") setIsFocused(false);
  });

  const rifm = useRifm({
    value: maskedValue,
    format: (str) => {
      const enhancedStr = adjustDate(str);

      return SLASH_INDEXES.includes(enhancedStr.length) &&
        enhancedStr.endsWith("/")
        ? enhancedStr.replace(ONLY_ONE_SLASH_REGEX, "")
        : formatDate(enhancedStr);
    },
    accept: /[\d.]/g,
    replace: (str) => {
      if (CURSOR_JUMP_POSITIONS.includes(str.length)) {
        setSelectionRange(internalRef.current, str.length + 1, str.length + 1);
      }

      return str.substring(0, mode === "range" ? 23 : 10);
    },
    onChange: enhancedOnChange,
  });

  useOnInteractOutside([internalRef, popoverRef], () => {
    if (!debouncedIsFocused) return;

    const hasCleared = maskedValue.length === 0;
    const hasPreviousValue = !!selectedDate;

    if (hasCleared) {
      datePickerRef.current?.setMonth(new Date());
      if (mode === "range") {
        (onChange as DateFieldProps<"range">["onChange"])?.({
          from: undefined,
          to: undefined,
        });
        setSelectedDate({
          from: undefined,
          to: undefined,
        });
      } else {
        (onChange as DateFieldProps<"single">["onChange"])?.(null);
        setSelectedDate(undefined);
      }
    } else if (hasPreviousValue) {
      datePickerRef.current?.setMonth(
        mode === "range"
          ? ((selectedDate as DatePickerRangeValue).from as Date)
          : (selectedDate as Date)
      );
      setMaskedValue(formatDate(selectedDate));
    } else {
      setMaskedValue("");
    }

    setIsFocused(false);

    requestAnimationFrame(() => onBlur?.());
  });

  useEffect(() => {
    if (value !== undefined) setSelectedDate(value);
  }, [value]);

  useEffect(() => {
    setMaskedValue(!selectedDate ? "" : formatDate(selectedDate));
  }, [selectedDate]);

  return (
    <>
      <TextField
        id={enhancedId}
        ref={mergeRefs(ref, internalRef)}
        type="tel"
        size={size}
        value={rifm.value}
        onFocus={enhancedOnFocus}
        onKeyDown={enhancedOnKeyDown}
        containerRef={popoverStore.setAnchorElement}
        errorMessage={isFocused ? "" : errorMessage}
        helperMessage={isFocused ? "" : helperMessage}
        warningMessage={isFocused ? "" : warningMessage}
        onInput={rifm.onChange}
        maxLength={
          mode === "range" ? FORMATTED_RANGE_DATE_LENGTH : FORMATTED_DATE_LENGTH
        }
        autoFocus={enhancedAutoFocus}
        addonAfter={
          <Icon
            size={size}
            name="calendar"
            aria-labelledby={suffixify(enhancedId, "label")}
          />
        }
        triggerChangeOnFocusedUnmount={false}
        {...props}
      />
      <Popover
        id={suffixify(enhancedId, "picker")}
        ref={popoverRef}
        modal={false}
        flip={flip}
        store={popoverStore}
        gutter={8}
        portal={portal}
        className={styles["picker"]}
        unmountOnHide={import.meta.env.MODE !== "test"}
        autoFocusOnShow={false}
        autoFocusOnHide={false}
      >
        <DatePicker
          ref={datePickerRef}
          mode={mode}
          value={selectedDate as any}
          toDate={toDate}
          fromDate={fromDate}
          onChange={onDateChange}
        />
      </Popover>
    </>
  );
};

const ForwardedDateField = forwardRef(DateField) as <
  Mode extends DatePickerMode = "single",
>(
  props: DateFieldProps<Mode> & { ref?: ForwardedRef<Ref> }
) => ReturnType<typeof DateField>;

export { ForwardedDateField as DateField };
